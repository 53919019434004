@import url(./colors.module.css);

.gamelog {
    width: 20vw;
    color: white;
    background-color: var(--secondary-dark-color);
    margin: 20px;
    border-radius: 10px;
    padding: 10px;
  }

@media screen and (max-width: 768px) {
  .gamelog {
    display: none;
  }
}