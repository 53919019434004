@import url(./colors.module.css);

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }

.modal {
    position: fixed;
    top: 30vh;
    width: 30%;
    height: 40%;
    z-index: 100;
    overflow: hidden;
    background-color: var(--primary-dark-color);
    color: #ccc;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}